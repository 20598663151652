import { MutableRefObject } from "react";
import {
  composeRenderProps,
  Button as RACButton,
  ButtonProps as RACButtonProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";
import { focusRing } from "./utils";

export interface ButtonProps extends RACButtonProps {
  variant?:
    | "primary"
    | "secondary"
    | "destructive"
    | "icon"
    | "default"
    | "text";
  padding?: "none" | "xs" | "small" | "medium";
  ref?: MutableRefObject<null>;
}

let button = tv({
  extend: focusRing,
  base: "flex items-center justify-center gap-x-2 shadow-sm",
  variants: {
    variant: {
      primary:
        "border-0 text-primary-main-100 bg-primary-main-500 hover:bg-primary-main-600 focus:bg-primary-main-600 text-base leading-[normal] font-medium rounded-sm",
      secondary:
        "text-primary-main-500 bg-primary-main-50 hover:bg-primary-main-90 focus:bg-primary-main-90 text-base leading-[normal] font-medium rounded-sm border border-primary-main-300 hover:border-secondary-main-10",
      default:
        "text-secondary-main-100 bg-secondary-main-10 hover:bg-secondary-main-20 focus:bg-secondary-main-20 text-base leading-[normal] font-medium rounded-sm border border-secondary-main-20",
      destructive:
        "bg-error-main-500 hover:bg-error-main-600 focus:bg-error-main-600 text-white text-base leading-[normal] font-medium rounded-sm",
      icon: "border-0 p-1 flex items-center justify-center text-secondary-main-100 hover:bg-primary-main-50 hover:text-primary-main-500 pressed:bg-primary-main-500 pressed:text-white disabled:bg-transparent",
      text: "border-0 p-0 flex items-center",
    },
    padding: {
      none: "p-0",
      xs: "p-1",
      small: "px-3 py-2",
      medium: "px-4 py-4",
    },
    isDisabled: {
      true: "bg-secondary-main-10 text-secondary-main-100 border-secondary-main-20",
    },
  },
  defaultVariants: {
    variant: "primary",
    padding: "medium",
  },
  compoundVariants: [
    {
      isDisabled: true,
      variant: ["primary", "secondary"],
      class:
        "hover:bg-secondary-main-10 hover:text-secondary-main-100 hover:border-secondary-main-20",
    },
  ],
});

/**
 * React-aria-components Button implementation with application styles (based on https://react-spectrum.adobe.com/react-aria-tailwind-starter/index.html?path=/docs/button--docs)
 * @param props containing
 * variant: tailwind variant to set wanted styles on component (possible values: "primary"
    | "secondary"
    | "destructive"
    | "icon"
    | "default"
    | "text")
 * padding: tailwind variant to set padding on component (possible values: "none" | "xs" | "small" | "medium")
 */
export default function Button(props: ButtonProps) {
  return (
    <RACButton
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        button({
          ...renderProps,
          variant: props.variant,
          padding: props.padding,
          className,
        })
      )}
    />
  );
}
